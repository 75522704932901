import { Search } from '@element-plus/icons';
import { reactive, ref } from 'vue';
import data from './MCS_MID_Installer_Export-data-json.json';
export default {
    components: {
        Search,
    },
    setup() {
        const input = ref('');
        const allProjects = ref(data);
        const filteredProjects = ref(data);
        const filters = reactive({
            s: ''
        });
        const state = reactive({
            noSearch: true
        });
        // onMounted(() => {
        //   console.log(data);
        // });
        const filtersChanged = (f) => {
            filters.s = f.s;
            const search = f.target.value.trim();
            // console.log(data);
            state.noSearch = false;
            const projects = allProjects.value.filter((p) => p['Company Name'].toLowerCase().indexOf(search.toLowerCase()) >= 0
                || p['MCS certificate number'].toLowerCase().indexOf(search.toLowerCase()) >= 0
                || p['Add 1'].toLowerCase().indexOf(search.toLowerCase()) >= 0
                //   || p.Add2.toLowerCase().indexOf(search.toLowerCase()) >= 0
                //   || p.Town.toLowerCase().indexOf(search.toLowerCase()) >= 0
                || p.County.toLowerCase().indexOf(search.toLowerCase()) >= 0
                || p.PCode.toLowerCase().indexOf(search.toLowerCase()) >= 0);
            //   || p.Website.toLowerCase().indexOf(search.toLowerCase()) >= 0);
            filteredProjects.value = projects;
        };
        return {
            input,
            filteredProjects,
            filters,
            filtersChanged,
            state
        };
    }
};
